@import "./_fonts.scss";

:root {
  --lightest-orange: #fee4d4;
  --light-orange: #ffd1b7;
  --brightest-orange: #f45f34;

  --black-letters: #101010;
  --lightest-gray: rgba(0, 0, 0, 0.05);
}
